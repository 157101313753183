import React from "react";

function P6ci() {
  return (
    <div className="content-div">
      <h3 className="content-h3">Google Maps/Street View</h3>
      <p className="content-p">Google Maps offers useful tools for inspection such as Google Street View and satellite imagery. These tools can be used to locate an asset and identify the asset type.</p>
      <p className="content-p">Digital images might not be reflective of on-the-ground reality. For some assets, the images on Google Maps are outdated, and an asset might have been redeveloped. Conversely, it is not possible to validate if older images do not show assets that are developed recently. There are also cases where Street View is not able to get very close to the asset, or defining features are blurred for privacy reasons.</p>
      <p className="content-p">Google Maps does provide the date of the imagery taken in both of their Street View and satellite images (in Google Earth). When validating the assets, check these dates to ensure you are working with a recent image.</p>

      <h3 className="content-h3">Ordnance Survey MasterMap Topography Layer</h3>
      <p className="content-p">The Ordnance Survey’s (OS) MasterMap Topography Layer contains information on a range of built assets in the UK. The product is updated every six weeks through a combination of in-person surveying and aerial photography. Given the frequent updates to this dataset and its comprehensive coverage, the locations of assets in this dataset can be taken as a source of truth. You can use this dataset to check how far the asset owner-provided asset coordinates are from the polygons as provided in MasterMap. </p>
      <p className="content-p">If you would like to verify additional attributes, you can also use the OS’s new National Geographic Database service, which is available through the OS Data Hub. You can download a bespoke data package of relevant OS-mapped attributes through the Select+Build program. </p>

      <h3 className="content-h3">Others (e.g. OSM)</h3>
      <p className="content-p">Other resources are also available for data validation. For example, Microsoft and Apple both have their own mapping and satellite imagery products. In some cases, for example when Google imagery is out of date, it is possible to get more up-to-date imagery from other providers.</p>
      <p className="content-p">There are also crowdsourced products available, such as OpenStreetMap (OSM). OSM provides free and unlimited API queries where other proprietary products do not. It also provides functionality not available in other products, such as the ability to search and visualise a polygon or multi-polygons on a map. This is not currently available in Google Maps, but it is extremely valuable in validating asset data with geometry in a polygon format.</p>
      <p className="content-p">However, it is important to recognise the limitations of using a crowdsourced map such as OSM. As the data is provided by the user communities on a voluntary basis, it is more common to experience data issues, such as missing data, data errors and out-of-date data.</p>
    </div>
  );
}

export default P6ci;
