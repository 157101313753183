import React from "react";

function P6ai() {
  return (
    <div className="content-div">
      <p className="content-p">Testing the accuracy of a data attribute involves checking a data sample against a “source of truth”: a source of information considered universally accurate. Some examples include:</p>
      <ul className="content-ul">
        <li className="content-li">Highly curated datasets (e.g. Ordnance Survey data)</li>
        <li className="content-li">Real-life checks (e.g. visiting an asset to check accuracy of its data)</li>
        <li className="content-li">Digital world-based checks (e.g. using imagery services, such as Google Maps)</li>
      </ul>
      <p className="content-p">Accuracy refers to the percentage of records in a sample that align with a source of truth. An example is the accuracy of location. If 90% of records in a sample have coordinates that correspond to the correct asset location on Google Maps, the accuracy level is 90%. A high accuracy level increases industry users’ trust in the data.</p>
      <p className="content-p">It is most important to test the accuracy of observable attributes that play a key role in industry users’ decisions: location and type. </p>

      <h3 className="content-h3">Location</h3>
      <p className="content-p">Location is a key factor in industry users’ site acquisition decisions. The recorded coordinates of an asset need to be close enough to the real-world asset location to inform decisions.</p>
      <h3 className="content-h3">Type</h3>
      <p className="content-p">Not all types of assets are suitable for all kinds of infrastructure deployments. To ensure that industry users select the right assets for their needs, the recorded type of an asset needs to be correct.</p>
      <h3 className="content-h3">Other asset attributes</h3>
      <p className="content-p">Attributes that are not easily observable, such as height or power supply, are not critical to validate at this stage. This information is typically checked during industry user site visits.</p>
      <p className="content-p">However, it is up to you and your organisation to decide which attributes to test during the initial data upload. </p>
    </div>
  );
}

export default P6ai;
