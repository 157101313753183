import React from "react";

function P8fi() {
  return (
    <div className="content-div">
      <p className="content-p">
        You have acquired, cleaned, and validated your asset data, and established roles and responsibilities for maintaining your data. Now what?
      </p>

      <h3 className="content-h3">Ensure the security of your platform</h3>
      <p className="content-p">
      Since digital asset platforms combine data on your assets with enquiries into connectivity infrastructure deployment, data hosted on the platform could be used for malicious intent. Before you hand over your data, complete due diligence checks on your chosen provider’s security measures:
      </p>

      <ul className="content-ul">
        <li className="content-li">What are their security measures for platform access?</li>
        <li className="content-li">How do they protect their database?</li>
        <li className="content-li">What are the export options for asset data?</li>
        <li className="content-li">What happens to your data if you close your account?</li>
      </ul>

      <h3 className="content-h3">Need help?</h3>
      <p className="content-p">Contact <a href="mailto: dcia-info@dcms.gov.uk">dcia-info@dcms.gov.uk</a>.</p>
    </div>
  );
}

export default P8fi;
