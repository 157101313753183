import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion } from "govuk-frontend";
import Breadcrumbs from "../Components/Breadcrumbs";
import ManualTitle from "../Components/ManualTitle";
import ContentsButton from "../Components/ContentsButton";
import ContentDict from "../Content-Pages/ContentDict";
import NavigationObject from "../NavigationObject.json";
import "./Details.scss";
import { Link } from "react-router-dom";
import "./Home.scss";

function Details(props) {
  let params = useParams();
  const content = NavigationObject.homePage.find((element) => element.id === params.id);
  const [sectionsHtml, setSectionsHtml] = useState();
  const [updateOnNextRender, setUpdateOnNextRender] = useState(false);
  const [initOnNextRender, setInitOnNextRender] = useState(false);

  const accordionRef = useRef();
  let lastContentId = -1;
  useEffect(() => {
    // when the content variable updates scroll to the top of the page
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // if there is new content
    if (lastContentId != content.id) {
      console.log("new content");
      //set variables to update the accordion on next render
      accordionRef.current.innerHtml = "";
      setSectionsHtml("");
      setUpdateOnNextRender(true);
    }
    lastContentId = content.id;
  }, [content]);

  useEffect(() => {
    //upates every render

    if (initOnNextRender) {
      console.log("Initialising Accordion");
      //initialise accordion
      const accordionObject = new Accordion(accordionRef.current);
      accordionObject.init();
      for (let sectionItr = 0; sectionItr < accordionRef.current.children.length; sectionItr++) {
        if (accordionRef.current.children[sectionItr].className.includes("govuk-accordion__section"))
          accordionObject.setExpanded(false, accordionRef.current.children[sectionItr]);
      }

      setInitOnNextRender(false);
    }
    if (updateOnNextRender) {
      // resets and updates the sections of the accordion to reflect what content the user has selected
      setSectionsHtml(GenerateSectionHtml());
      setUpdateOnNextRender(false);
      setInitOnNextRender(true);
    }
  });
  // builds the accordion sections from the content that is currently selected
  function GenerateSectionHtml() {
    return content.sections.map((section) => {
      return (
        <div className="govuk-accordion__section " key={section.title}>
          <div className="govuk-accordion__section-header">
            <h2 className="govuk-accordion__section-heading">
              <span className="govuk-accordion__section-button" id="accordion-default-heading-1">
                {section.title}
              </span>
            </h2>
          </div>
          <div id="accordion-default-content-1" className="govuk-accordion__section-content" aria-labelledby="accordion-default-heading-1">
            <div className="govuk-body">
              {section.links.map((item) => {
                if (item.inline === true) {
                  return <div>{ContentDict[item.pageName]}</div>;
                } else {
                  return (
                    <div className="gem-c-govspeak govuk-govspeak">
                      {item.title.length > 0 ? <h3 className="govuk-link-item-title"> {item.title} </h3> : null}
                      {item.description.length > 0 ? <p className="govuk-link-item-explanation">{item.description}</p> : null}
                      <Link to={`/Details/${item.pageName}`} className="govuk-link-item-pageName">
                        {item.pageName}
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      );
    });
  }
  return (
    <>
      <Breadcrumbs page={"Home/Details/" + params.id}></Breadcrumbs>
      <ManualTitle GetSearchResults={props.GetSearchResults}></ManualTitle>
      <ContentsButton input="Contents" />
      <h1 className="content-h1">{content.title}</h1>
      {params.id !== "1" ? <div className="intro">{ContentDict[`${content.id}_intro`]}</div> : null}

      <div className="govuk-accordion" data-module="govuk-accordion" id="accordion-default" ref={accordionRef}>
        {sectionsHtml}
      </div>

      <nav class="govuk-pagination govuk-pagination--block" role="navigation" aria-label="results">
        {content.id > 1 ? (
          <div class="govuk-pagination__prev">
            <Link class="govuk-link govuk-pagination__link" to={`/Details/${(parseInt(params.id) - 1).toString()}`} href="#" rel="prev">
              <svg
                class="govuk-pagination__icon govuk-pagination__icon--prev"
                xmlns="http://www.w3.org/2000/svg"
                height="13"
                width="15"
                aria-hidden="true"
                focusable="false"
                viewBox="0 0 15 13"
              >
                <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>
              </svg>
              <span class="govuk-pagination__link-title">Previous</span>
              <span class="govuk-visually-hidden">:</span>

              <span class="govuk-pagination__link-label">
                {NavigationObject.homePage.find((element) => element.id === (parseInt(params.id) - 1).toString()).title}
              </span>
            </Link>
          </div>
        ) : null}
        {content.id < 8 ? (
          <div class="govuk-pagination__next">
            <Link class="govuk-link govuk-pagination__link" to={`/Details/${(parseInt(params.id) + 1).toString()}`} rel="next">
              {" "}
              <svg
                class="govuk-pagination__icon govuk-pagination__icon--next"
                xmlns="http://www.w3.org/2000/svg"
                height="13"
                width="15"
                aria-hidden="true"
                focusable="false"
                viewBox="0 0 15 13"
              >
                <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>
              </svg>{" "}
              <span class="govuk-pagination__link-title">Next</span>
              <span class="govuk-visually-hidden">:</span>
              <span class="govuk-pagination__link-label">
                {NavigationObject.homePage.find((element) => element.id === (parseInt(params.id) + 1).toString()).title}
              </span>
            </Link>
          </div>
        ) : null}
      </nav>

      <div className="glossary">
        <h2 className="content-h1">Glossary</h2>
        {ContentDict[`${content.id}_glossary`]}
      </div>
    </>
  );
}

export default Details;
