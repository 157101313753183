import React from "react";
import img3ai from "../Images/3ai.png";
function P3ai() {
  return (
    <div className="content-div">
      <p className="content-p">
        A data ecosystem is a term for the stakeholders, organisations, people, information, processes and systems around the data in a defined area
        or domain. A data ecosystem map organises this information visually for ease of understanding.
      </p>
      <p className="content-p">
        Undertaking a data ecosystem mapping exercise requires working with stakeholders on data discovery: finding out what datasets they hold that
        will benefit industry users.
      </p>
      <p className="content-p">
        Please see a data ecosystem map example below. An explanation of what each of the symbol’s mean is given in the Ecosystem map template.
      </p>
      <div className="content-img-container">
        <img className="content-img" src={img3ai} style={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default P3ai;
