import React from "react";

function P7ci(props) {
  return (
    <div className="content-div">
      <p className="content-p">
        Given the risks of public knowledge that assets may be used for connectivity infrastructure, it is important to keep the data transfer process secure. The transfer starts when data is obtained from the source systems, not only when processed data is shared with the platform providers. You should consider the following when exporting and transferring data:
      </p>

      <ol className="content-ol">
        <li className="content-li">
          The transfer process is initiated when raw data is exported from the source system. If this is a third-party application, investigate their security features. Make sure the data is stored in an appropriate location upon export. This should require users to successfully undergo multi-factor authentication (MFA). 
        </li>
        <li className="content-li">
          Remove information from the dataset that you do not wish to share with the platform providers. This may be handled as part of your data cleaning and consolidation processes. 
        </li>
        <li className="content-li">
          Once the data is ready for transfer, seek release authorisation based on your data policy. If you don’t have a release policy, you should consider putting one in place.
        </li>
        <li className="content-li">
          If release authorisation is successful, consider encryption needs of files and in the communication channels and act accordingly.
        </li>
        <li className="content-li">
          Send the information to the destination system via an appropriate method. It is not advisable to share data over e-mail or store data unnecessarily in intermediate devices (such as USB flash drives). If unavoidable, take steps to minimise risk, such as the use of VPNs and encryption of devices and files.
        </li>
        <li className="content-li">
          Be aware of the security features available in platform provider systems. The destination system should require users to undergo MFA to initiate the transfer process and access the data. 
        </li>
      </ol>
      <p className="content-p">
        Keep in mind that storing and working with data will require human intervention. Consider how well your security processes integrate with your existing ways of working. It is important that users receive suitable training and support to minimise errors, but also avoid frustration and attempts to bypass processes.
      </p>
      <p className="content-p">These principles are based on security guidance from the National Cyber Security Centre (NCSC) and the Centre for the Protection of National Infrastructure (CPNI). For further details, please refer to these organisations’ websites. Engage with your internal IT department to understand your existing security processes and measures.</p>
    </div>
  );
}

export default P7ci;
