import React from "react";

function P8ai() {
  return (
    <div className="content-div">
      <p className="content-p">
        The same stakeholders who initially prepared data for the digital asset platform need to be involved going forward. However, their roles will likely be more on an ad-hoc basis. 
      </p>
      <p className="content-p">
        Below is a non-exhaustive list of continuing data maintenance activities and the roles involved. Use this list as a guide for planning and resourcing. Keep in mind that your own data management process may differ from what is outlined here. 
      </p>

      <p className="content-p">
        <b className="content-b">Activity 1: Operations management</b> - Key tasks include engaging with stakeholders, ensuring the correct governance procedures are in place, aligning with your local data strategy and managing the sustainability of the process.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to engage with the various stakeholders and lead on operations management.
        </li>
        <li className="content-li">Senior local authority stakeholders to make the process operational and sustainable.</li>
      </ul>

      <p className="content-p">
        <b className="content-b">Activity 2: Data processing</b> - Key tasks include cleaning new datasets to the necessary requirements, refreshing data and providing continuous improvement support for existing datasets.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">Digital coordinators/ Data champions to engage with stakeholders and clean datasets.</li>
        <li className="content-li">Data processors to clean the datasets.</li>
      </ul>

      <p className="content-p">
        <b className="content-b">Activity 3: Data discovery</b> - Key tasks include engaging with industry stakeholders and investigating the value of any new datasets.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">Digital coordinators/ Data champions to engage with industry stakeholders and collect information.</li>
        <li className="content-li">Data processors to advise on the datasets they manage.</li>
        <li className="content-li">Asset owners to provide information on datasets.</li>
        <li className="content-li">Senior local authority stakeholders to provide guidance on what stakeholders to contact.</li>
      </ul>

      <p className="content-p">
        <b className="content-b">Activity 4: Responding to requests via a digital asset platform</b> - Key tasks include responding to industry users’ enquiries about your assets. 
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">Digital coordinators/ Data champions to set up and manage the process</li>
        <li className="content-li">Asset owners to provide information on assets and engage in dialogue with industry users. </li>
        <li className="content-li">Senior local authority stakeholders to provide the resources required for this process. </li>
      </ul>
    </div>
  );
}

export default P8ai;
