import React from "react";
import Download from "../Components/Download";
function P8bi() {
  return (
    <div className="content-div">
      <p className="content-p">
        Data governance involves establishing roles and responsibilities for each stakeholder, ensuring the data is available, secure, and of the desired quality. 
      </p>
      <p className="content-p">
        The following data governance considerations emerged from the DCIA pilots:
      </p>
      <ul className="content-ul">
        <li className="content-li">Who will own the intellectual rights to the platforms.</li>
        <li className="content-li">Who is responsible for the maintenance of the platforms.</li>
        <li className="content-li">Who owns the data uploaded to the platforms.</li>
        <li className="content-li">Data security of the platforms.</li>
      </ul>

      <p className="content-p">
        These considerations should be covered in your platform provider’s terms and conditions. It is up to your organisation to read these conditions and agree to the terms.   
      </p>
      <p className="content-p">
      As a local authority you will need to govern your own processes as well. Three key areas for data governance you should consider include data quality management, data security management, and data operations management. A description of each of these areas is given below. 
      </p>

      <h3 className="content-h3">Data quality management</h3>
      <p className="content-p">
        Data quality is always a work in progress, so all stakeholders need to participate in continuous improvement. Areas for consideration around data quality management: 
      </p>
      <ul className="content-ul">
        <li className="content-li">Discussing and defining the minimum data requirements with platform providers</li>
        <li className="content-li">Implementing the minimum data requirements</li>
        <li className="content-li">Actioning data quality feedback requests from platform providers</li>
        <li className="content-li">Checking the location accuracy of the infrastructure asset data </li>
        <li className="content-li">Records and archiving</li>
      </ul>

      <h3 className="content-h3">Data security management</h3>
      <p className="content-p">
        Your data needs to be secure while at rest, while being transformed, while being transferred, and when being viewed. To minimize the risk of a data breach, take a no-stone-unturned approach to data security management. Areas for consideration around data security management:
      </p>
      <ul className="content-ul">
        <li className="content-li">Secure transfer from dataset owners </li>
        <li className="content-li">Ensuring data is secure while being cleaned </li>
        <li className="content-li">Secure transfer to your digital asset management platform </li>
        <li className="content-li">GDPR regulations </li>
        <li className="content-li">Managing access to view or transform the data </li>
      </ul>

      <h3 className="content-h3">Data operations management</h3>
      <p className="content-p">
        These activities support the long-term sustainability and operation of the process. Areas for consideration around data operations management:
      </p>
      <ul className="content-ul">
        <li className="content-li">Stakeholder management and review process</li>
        <li className="content-li">Securing the supply of data</li>
        <li className="content-li">Data discovery</li>
        <li className="content-li">Processing the data to the requirements</li>
        <li className="content-li">Maintenance and development of data pipeline/ data refresh</li>
        <li className="content-li">Ensuring any necessary license agreements are in place. For example, Ordnance Survey licensing if applicable</li>
      </ul>
    </div>
  );
}

export default P8bi;
