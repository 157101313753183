import React from "react";
import { Link } from "react-router-dom";

function P2ci() {
  return (
    <div className="content-div">
      <p className="content-p">Use this toolkit to help address some of the barriers you are likely to face.</p>

      <p className="content-p">
        <b className="content-b">Buy-in from stakeholders</b>
      </p>
      <p className="content-p">
        Local authorities may be stretched in terms of the resources they can provide to this process and stakeholders may be reluctant to take part.
        This toolkit provides templates and information to help other stakeholders understand the process.
      </p>
      <p className="content-p">
        <b className="content-b">Engagement from stakeholders </b>
      </p>
      <p className="content-p">
        Lack of communication or slow responses from asset data owners will likely extend the length of the process. In{" "}
        <Link to={"/Details/3/"}>Section 3</Link> and <Link to={"/Details/4/"}>4</Link> of this toolkit, you will find questionnaires and data request
        templates that outline what is expected from asset data owners.
      </p>
      <p className="content-p">
        <b className="content-b">Data ecosystem not documented</b>
      </p>
      <p className="content-p">
        There is rarely a single source of truth outlining available datasets within the local authority. Use the data ecosystem map templates in
        <Link to={"/Details/3/"}> Section 3</Link> to help you start documenting your organisation’s datasets.
      </p>
      <p className="content-p">
        <b className="content-b"> Datasets missing information</b>
      </p>
      <p className="content-p">
        Datasets may be missing key pieces of information, which requires working with asset data owners to fill in the gaps. Use the data request
        templates in <Link to={"/Details/4/"}>Section 4</Link> to ensure that datasets contain the necessary information.
      </p>
      <p className="content-p">
        <b className="content-b">Datasets not standardised</b>
      </p>
      <p className="content-p">
        Datasets may be in many different formats and will not be standardised in terms of the information they provide.{" "}
        <Link to={"/Details/5/"}>Section 5</Link> outlines a process to standardise your datasets.
      </p>
      <p className="content-p">
        <b className="content-b">Datasets not interlinked</b>
      </p>
      <p className="content-p">
        Datasets may be stored in different systems and have different people responsible, making coordination difficult. The data ecosystem map
        template in <Link to={"/Details/3/"}>Section 3</Link> will help you identify the stakeholders you need to involve.
      </p>
      <p className="content-p">
        <b className="content-b">Providing a persistent unique ID</b>
      </p>
      <p className="content-p">
        Asset owners may not have a unique ID process in place for assets. Providing a unique ID for each asset is a mandatory requirement. Once a
        unique ID is provided to a platform provider, it is critical that it never changes, as it will affect future data refreshes for the digital
        asset platform. The data request templates in <Link to={"/Details/4/"}>Section 4</Link> explain this requirement, allowing asset owners to
        implement a unique ID system for their asset data.
      </p>
    </div>
  );
}

export default P2ci;
