import React from "react";

function P3di() {
  return (
    <div className="content-div">
      <p className="content-p">
      For each asset class, collect the following information about the corresponding asset dataset:
      </p>
      <ol className="content-ol">
        <li className="content-li">
          <b className="content-b">Asset owner - </b>To understand who has ownership of the asset. 
        </li>
        <li className="content-li">
          <b className="content-b">Data owner - </b>To understand who manages the dataset. 
        </li>
        <li className="content-li">
          <b className="content-b">Dataset type - </b>To understand what the asset type is, so you can decide what types of assets you will make available to industry users. 
        </li>
        <li className="content-li">
          <b className="content-b">How is the data collected and inputted into the system - </b> To understand who is responsible for gathering the data and who can fill in information gaps. It will also help you understand the process involved in collecting the data.
        </li>
        <li className="content-li">
          <b className="content-b">Source system - </b>To understand where the data is stored and if the data can be exported using an API or manually. 
        </li>
        <li className="content-li">
          <b className="content-b">Access rights - </b>To understand if the dataset is open access, requires permission or has no access allowed.
        </li>
        <li className="content-li">
          <b className="content-b">Format - </b>To understand the syntax, encoding, file format or media type of the dataset so you can prepare a plan to clean it.  
        </li>
        <li className="content-li">
          <b className="content-b">Update frequency - </b>To understand how often the data will need to be refreshed on the digital asset platform. 
        </li>
      </ol>
      <p className="content-p">
        While it is helpful to gather as much information as possible about each dataset, gaps in information should not be a barrier to progress. You can start acquiring datasets from relevant stakeholders as soon as you know where to find them, then fill in more details about the datasets later. Mapping your data ecosystem will be an ongoing task. 
      </p>
    </div>
  );
}

export default P3di;
