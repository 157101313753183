import React, { useState } from 'react';
import Download from './Download';
import "./VersionDownload.scss";

function VersionDownload(props) {

    const [selectValue, setSelectValue] = useState(0);

    const onChange = (event) => {
        setSelectValue(event.target.value);
    }

    return (
        <div class="govuk-form-group">
            {props.download_options[selectValue]}
            <div className="govuk-dropdown">
                <label class="govuk-label" for="subject">Download file type</label>
                <select class="govuk-select" id="subject" name="subject" onChange={onChange}>
                    {props.download_titles.map((title, downloadId) => <option value={downloadId} select={downloadId === 0 ? true : false}> {title}</option>)}
                </select>
            </div>
        </div>
    );
}

export default VersionDownload;