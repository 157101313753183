import React from "react";

function P7ai(props) {
  return (
    <div className="content-div">
      <p className="content-p">
      The most straightforward option is to manually send data to your platform provider. Your platform provider will set up an online secure storage location, such as a Microsoft SharePoint site. You will then upload your data to the site.
      </p>
      <p className="content-p"><b><u>Avoid using email or portable devices (e.g. USB sticks) for data transfer purposes.</u></b></p>
      
      <h3 className="content-h3">Advantages</h3>
      <p className="content-p">The main advantage of manual data transfer is simplicity.</p>
      <ul className="content-ul">
        <li className="content-li">
          Manual transfer does not require specialist software or technology. 
        </li>
        <li className="content-li">
          Since the transfer is based on software that is already part of your organisation’s daily activities, staff responsible for the transfer are unlikely to need significant training to carry out the process.
        </li>
        <li className="content-li">
          There is no significant cost to implementing this type of transfer.
        </li>
      </ul>

      <h3 className="content-h3">Disadvantages</h3>
      <p className="content-p">
        The disadvantages of manual data transfer are the long-term effort and consistency of the process and data.
      </p>
      <ul className="content-ul">
        <li className="content-li">
          Since staff must upload data to the sharing site, manual data transfer requires ongoing resource commitment. The transfer is also susceptible to staff circumstances, such as annual leave, sick leave, or resignation
        </li>
        <li className="content-li">
          The transfer process is human-led and therefore more prone to inconsistencies. Different individuals may be responsible for transferring data at different points in time, who use different file formats or naming conventions for files. If asset data is automatically processed from the sharing site, these inconsistencies could cause problems for platform providers. 
        </li>
      </ul>
    </div>
  );
}

export default P7ai;
