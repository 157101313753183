import React from "react";

function P4di() {
  return (
    <div className="content-div">
      <p className="content-p">
        Listed below are datasets that provide more context around your assets, which can be useful to industry users if available at a local level.
      </p>
      <p className="content-p">
        These datasets are not required for basic platform functionality. However, they can help streamline interactions with industry users or help you direct demand to where it is needed. Notify your platform provider if you do own any of these datasets.
      </p>
      <h3 className="content-h3">
        Security considerations
      </h3>
      <p className="content-p">
        Some of these datasets contain highly sensitive information. To avoid compromising the security of critical infrastructure, do not upload these datasets to the platform in entirety. If you do hold this information, upload only the minimum information required from these datasets. For example, you could rate power or fibre availability in grid cells.
      </p>

      <table class="govuk-table">
        <thead class="govuk-table__head">
          <tr class="govuk-table__row">
            <th scope="col" class="govuk-table__header">
              Dataset
            </th>
            <th scope="col" class="govuk-table__header">
              Description
            </th>
            <th scope="col" class="govuk-table__header">
              Why it might be useful
            </th>
          </tr>
        </thead>
        <tbody class="govuk-table__body">
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Fibre network
            </th>
            <td class="govuk-table__cell">Data to show the locations of the existing fibre access points network on a map.</td>
            <td className="govuk-table__cell">
              Show industry users where connectivity infrastructure exists to minimise construction of new infrastructure.
            </td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Power network
            </th>
            <td class="govuk-table__cell">Data to show the locations of the existing power network on a map.</td>
            <td className="govuk-table__cell">
              Show industry users where power infrastructure exists to minimise construction of new infrastructure.
            </td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Sub-population projections (future population forecasts)
            </th>
            <td class="govuk-table__cell">
              Sub-council population projections provide an indication of the potential future population size of LAs areas.
            </td>
            <td className="govuk-table__cell" rowSpan={3}>
              Projections can be used to highlight areas of potential demand growth. This will highlight business needs to industry users.
            </td>
          </tr>

          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Local planning designations
            </th>
            <td className="govuk-table__cell">
              Datasets that show what areas planning permissions have been granted and to show planning applications submitted to councils.
            </td>
          </tr>
          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Economic development areas
            </th>
            <td className="govuk-table__cell">
              Areas where authorities have designated and prioritized for development
            </td>
          </tr>

          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Population database (population forecast for a time of day/ specific area)
            </th>
            <td class="govuk-table__cell">Database which shows the predicted population of an area at different times of day.</td>
            <td className="govuk-table__cell">Visualise areas of transient demand.</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Lidar datasets
            </th>
            <td class="govuk-table__cell">
              Method for determining distances by targeting an object with a laser. It can be used to make digital 3-D representations of areas on the earth's surface.
            </td>
            <td className="govuk-table__cell">Data may be useful for point-of-view assessments of potential infrastructure sites.</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Conservation areas
            </th>
            <td class="govuk-table__cell">
              Designated areas to help manage and protect places of special architectural or historical significance
            </td>
            <td className="govuk-table__cell" rowSpan={5}>
              Planning permission may be more difficult to attain in these areas. Knowing this could save industry users time by not trying to acquire sites in these areas.
            </td>
          </tr>

          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Greenbelts
            </th>
            <td className="govuk-table__cell">
              Dataset which shows Green Belt boundaries as designated by LAs.
            </td>
          </tr>
          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Sites of special scientific interest
            </th>
            <td className="govuk-table__cell">
              Dataset that shows the areas designated as Sites of Special Scientific Interest under the Wildlife and Countryside Act (1981)
            </td>
          </tr>
          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Nature reserves
            </th>
            <td className="govuk-table__cell">
              A National Nature Reserve is the land declared under the National Parks and Access to the Countryside Act 1949 or Wildlife and Countryside Act (1981) as amended.
            </td>
          </tr>
          <tr className="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Wildlife corridors
            </th>
            <td className="govuk-table__cell">
              Areas of habitat that allow interconnection between wildlife sites
            </td>
          </tr>

          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Flood plains
            </th>
            <td class="govuk-table__cell">Areas on a map that have been designated as flood plains where that area is liable to flooding</td>
            <td className="govuk-table__cell">Demonstrate areas where infrastructure could potentially get damaged due to flooding</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Public rights of ways
            </th>
            <td class="govuk-table__cell">Dataset which shows where public rights of way are on a map</td>
            <td className="govuk-table__cell">Industry users can see access routes for potential sites</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Gullies
            </th>
            <td class="govuk-table__cell">Drainage systems located close to roads and highways</td>
            <td className="govuk-table__cell">Demonstrate where existing gullies lie, which would lower construction costs for deployment</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Grass cutting areas/ verges
            </th>
            <td class="govuk-table__cell">Grass areas located beside roads and highways</td>
            <td className="govuk-table__cell">Land area that could be suitable for monopole deployment</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default P4di;
