import React from "react";

function P6bi() {
  return (
    <div className="content-div">
      <h3 className="content-h3">
        Description of accuracy validation workflow
      </h3>
      <p className="content-p">
        The testing process will typically include the following activities:
      </p>

      <ul className="content-ul">
        <li className="content-li">
          <b className="content-b">
            Decide on what data attributes need to be tested:
          </b>{" "}
          It might be impractical to test the accuracy of each data attribute in
          your dataset. Focus on attributes that are critical for industry
          users.
        </li>
        <li className="content-li">
          <b className="content-b">Define the "source of truth” </b> for each
          data attribute you need to test (e.g. other datasets, real-life assets
          or digital imagery).
        </li>
        <li className="content-li">
          <b className="content-b">Set a target accuracy: </b> Decide on what
          you consider as an acceptable accuracy level. Consider the limitations
          of your methodology. For example, images in Google Maps or crowd
          sourced OpenStreetMap are often outdated. These sources might show
          assets that no longer exist or omit assets that were installed after
          image capture. Engage with your platform provider to ensure the target
          accuracy is adequate.
        </li>
        <li className="content-li">
          <b>Set a sample size:</b> A sample size is the number of data records
          that will be checked against the source of truth. There are
          statistical and practical considerations associated with choosing a
          sample size:
        </li>
        <ul className="content-ul content-odd-ul">
          <li className="content-li">
            Sample size depends on the required confidence level, required
            margin of error, and total number of assets in your dataset. The
            below examples indicate minimum sample sizes for different
            combinations of these factors.
          </li>
          <li className="content-li">
            For a confidence level of 99% with a margin of error of 10%
          </li>
          <ul className="content-ul content-ul-3">
            <li className="content-li">
              If your data has 10,000 records, the minimum sample size is 164
            </li>
            <li className="content-li">
              If your data has 1,000 records, the minimum sample size is 143
            </li>
          </ul>
          <li className="content-li">
            For a confidence level of 90% with a margin of error of 10%
          </li>
          <ul className="content-ul content-ul-3">
            <li className="content-li">
              If your data has 10,000 records, the minimum sample size is 68
            </li>
            <li className="content-li">
              If your data has 2,000 records, the minimum sample size is 66
            </li>
          </ul>
          <li className="content-li">
            For a confidence level of 80% with a margin of error of 10%
          </li>
          <ul className="content-ul content-ul-3">
            <li className="content-li">
              If your data has 20,000 records, the minimum size is 41
            </li>
            <li className="content-li">
              If your data has 5,000 records, the minimum size is 41
            </li>
          </ul>
          <li className="content-li">
            For a confidence level of 70% with a margin of error of 10%
          </li>
          <ul className="content-ul content-ul-3">
            <li className="content-li">
              If your data has 10,000 records, the minimum sample size is 27
            </li>
            <li className="content-li">
              If your data has 1,000 records, the minimum sample size is 27
            </li>
          </ul>
        </ul>
        <li className="content-li">
          <b>Sample your dataset:</b>Choose specific asset records from the
          whole dataset to test. For statistically robust outputs, ensure that
          your sampling process is random and independent. This means all
          records should be selected randomly, and the selection of one sample
          should not impact the selection of the next. Excel, Python, and
          desktop GIS software all have functions to generate random independent
          samples from a dataset.
        </li>
        <li className="content-li">
          <b>Test your sample:</b> Check your asset record against the source of
          truth. Mark samples as Pass or Fail. For example, to test the location
          and type of an asset, input the coordinates into Google Maps (or
          OpenStreetMap), then confirm asset existence and type using imagery
          services. Upon successful confirmation, mark the asset as a “Pass”.
        </li>
        <li className="content-li">
          <b className="content-b">Obtain a conclusion:</b> Count the number of
          “Pass” data records and compare that to the size of your sample. For
          example, 45/50 samples marked as “Pass” or 90% of “Pass” samples.
          Compare this to your target to determine if your data is considered as
          sufficiently accurate.{" "}
        </li>
      </ul>

      <h3 className="content-h3">What happens then?</h3>
      <ul className="content-ul">
        <li className="content-li">
          <b className="content-b">
            Data is accurate enough (i.e. accuracy is higher than your set
            target)
          </b>
          <ul className="content-ul-2">
            <p className="content-p">
              You are ready to move on to the next phase of your data journey
              with confidence.
            </p>
            <p className="content-p">
              If your accuracy level is less than 100%, send asset records that
              failed validation back to the data owners, so they can correct
              these records or investigate the source of error. This will
              increase the quality of your data over time.
            </p>
            <p className="content-p">
              Since datasets change, data validation should not be a one-time
              exercise. Repeat validation with each data upload to ensure
              confidence in your data.
            </p>
          </ul>
        </li>

        <li className="content-li">
          <b className="content-b">
            Data accuracy is lower than your set target
          </b>
        </li>
        <ul className="content-odd-ul">
          <p className="content-p">
            Your platform provider can advise you on next steps for the dataset.
            In the meantime, work with the data owner to identify potential
            causes of low accuracy.
          </p>
          <p className="content-p">
            Consider how important the tested data attribute is. If you
            validated an attribute that is not critical to industry users, the
            data can still be used without this attribute.
          </p>
        </ul>
      </ul>
    </div>
  );
}

export default P6bi;
