import React, { useState } from "react";
import "./ManualTitle.scss";
import NavigationObject from "../NavigationObject.json";
import { useNavigate } from "react-router-dom";

function ManualTitle(props) {
  const [search, setSearch] = useState("");
  const navigation = useNavigate();

  return (
    <header className="grid-row manuals-header">
      <div className="grid-columns-two-thirds">
        <h1 className="heading-1">DCIA Data Integration Toolkit</h1>
        <div className="metadata">
          <p>Published: 24th March 2023</p>
        </div>
        <div className="in-manual-search">
          <form action="/search/all">
            <input type="hidden"></input>
            <div className="search">
              {search.length == 0 ? <label className="search-label">Search this manual</label> : null}
              <div className="search-item-wrapper">
                <input
                  enterKeyHint="search"
                  className="search-input"
                  type="search"
                  onChange={(element) => {
                    setSearch(element.target.value);
                  }}
                ></input>
                <div className="search-submit-wrapper">
                  <button
                    className="search-submit"
                    type="submit"
                    enterKeyHint="search"
                    onClick={(event) => {
                      event.preventDefault();
                      let results = props.GetSearchResults(search);
                      navigation("/Search/", { state: { searchResults: results } });
                    }}
                  >
                    <svg
                      className="search-icon"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <circle cx="12.0161" cy="11.0161" r="8.51613" stroke="currentColor" strokeWidth="3"></circle>
                      <line x1="17.8668" y1="17.3587" x2="26.4475" y2="25.9393" stroke="currentColor" strokeWidth="3"></line>
                    </svg>
                    "Search"
                  </button>
                </div>
              </div>
            </div>
            <p> NOTE: Search functionality only extends to heading names and descriptions and not the contents </p>
          </form>
        </div>
      </div>
    </header>
  );
}
export default ManualTitle;
