import React from "react";
import { Link } from "react-router-dom";
import NavigationObject from "../NavigationObject.json";
import "./Navbar.scss";

function Navbar(props) {
  return (
    <header class="govuk-header " role="banner" data-module="govuk-header">
      <div class="govuk-header__container govuk-width-container">
        <div class="govuk-header__content">
          <nav aria-label="Menu" class="govuk-header__navigation ">
            <button
              type="button"
              class="govuk-header__menu-button govuk-js-header-toggle"
              aria-controls="navigation"
              aria-label="Show or hide menu"
              hidden
            >
              Menu
            </button>
            <ul id="navigation" class="govuk-header__navigation-list">
              {NavigationObject.homePage.map((element, idex) => {
                return (
                  <li class="govuk-header__navigation-item">
                    <Link class="govuk-header__link" to={"Details/" + element.id + "/"}>
                      {element.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
