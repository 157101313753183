import React from "react";

function P6_glossary() {
    return (
        <div className="content-div">
            <ul className="content-ul">
                <li className="content-li"><b>Attribute</b>: A specific piece of data attached to a given asset. In a spreadsheet where rows represent assets, each column would represent an attribute. </li>
                <li className="content-li"><b>Confidence level</b>: The probability that the true value being studied falls within a specified range of values. For example, if a survey shows, with a 95% level of confidence, that the average weight of people in a city is between 140 and 150 lbs; this means that there is a 95% chance that the real average weight is within that range. Also, there is a 5% chance that the real average is either smaller than 140 or higher than 150.</li>
                <li className="content-li"><b>Margin of error</b>: A way of expressing error in a measurement or survey. In practical terms, if 80% of your samples successfully pass the test and your margin of error is 10%, you could consider that this means that between 70% and 90% of your samples pass your test in reality.</li>
                <li className="content-li"><b>Sample</b>: In this context, the group of assets that has been selected for validation.</li>
                <li className="content-li"><b>Sampling</b>: The process of selecting what asset records are used in data validation.</li>
                <li className="content-li"><b>Source of truth</b>: A source of information that is representative of reality. This can take several forms, such as datasets, images of locations (e.g. satellite images, photos) or a real location or object.</li>
            </ul>
        </div>
    );
}

export default P6_glossary;