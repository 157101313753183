import React from "react";

function P1ai() {
  return (
    <div className="content-div">
      <ol className="content-ol">
        <li className="content-li">
          Leverage growing demand for connectivity infrastructure. Your assets could be used to speed up the roll out of advanced wireless networks, improving connectivity in your area. Improved connectivity supports local ambitions for economic growth and enables public service transformation.
        </li>
        <li className="content-li">
          Improve or build new relationships with telecommunications industry stakeholders. Adopting a digital asset platform to streamline communications will reduce the time and costs needed to address industry demand. Industry users may also face fewer failed planning applications. As a result, your organisation is likely to face fewer complaints from industry and local communities.
        </li>
      </ol>

      <p className="content-p">
      For further information on the benefits of adopting a digital asset platform, please refer to the Benefits Realisation report, to be published on the <a href="https://www.gov.uk/guidance/digital-connectivity-infrastructure-accelerator-programme">DCIA webpage</a>.
      </p>
    </div>
  );
}

export default P1ai;
