import React from "react";

function P7_glossary() {
    return (
        <div className="content-div">
            <ul className="content-ul">
                <li className="content-li"><b>API</b>: An application programming interface (API) allows two or more computer programs to communicate with each other. In the context of this toolkit, APIs can be used to carry out data transfer automatically.</li>
                <li className="content-li"><b>Encryption</b>: A process that takes information and converts it into a code that is not intuitively understandable by a person or representative of the original information. </li>
            </ul>
        </div>
    );
}

export default P7_glossary;