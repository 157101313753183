import React from "react";
import "./Download.scss";
import downloadDocument from "../Images/download_document.png";
function Download(props) {
  return (
    <div className="download_container">
      <div className="left_container">
        <img className="download_image" src={downloadDocument} />
      </div>
      <div className="right_container">
        <a download href={props.url}>
          <h2 className="content-h2 govuk-link">{props.content.title}</h2>
        </a>
        <p className="download_details">{props.content.details}</p>
        <p className="download_description">{props.content.description}</p>
      </div>
    </div>
  );
}
export default Download;
