import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import NavigationObject from "../NavigationObject.json";
import "./SearchResults.scss";

function SearchResults(props) {
  const location = useLocation();
  const navigation = useNavigate();
  const [search, setSearch] = useState("");

  //inputs are the search results from the search function, this function simply converts those search results into a standard format
  function GetNavigationDetails(navigation) {
    //Standard format formentioned
    let navigationDetails = {
      link: "",
      title: [],
      details: "",
    };
    if (navigation.length > 0) {
      navigationDetails.link = "/";
      navigationDetails.title.push(NavigationObject.homePage[navigation[0]].title);
      navigationDetails.details = NavigationObject.homePage[navigation[0]].description;
    }
    if (navigation.length > 1) {
      navigationDetails.link = `/Details/${navigation[0] + 1}`;
      navigationDetails.title.push(NavigationObject.homePage[navigation[0]].sections[navigation[1]].title);
      navigationDetails.details = NavigationObject.homePage[navigation[0]].sections[navigation[1]].description;
    }
    if (navigation.length > 2) {
      navigationDetails.link = `/Details/${NavigationObject.homePage[navigation[0]].sections[navigation[1]].links[navigation[2]].pageName}`;
      navigationDetails.title.push(NavigationObject.homePage[navigation[0]].sections[navigation[1]].links[navigation[2]].title);
      navigationDetails.details = NavigationObject.homePage[navigation[0]].sections[navigation[1]].links[navigation[2]].description;
    }

    navigationDetails.title = navigationDetails.title.join(" > ");

    return navigationDetails;
  }

  return (
    <div>
      <h1>Search</h1>
      <form action="/search/all">
        <input type="hidden"></input>
        <div className="search">
          {search.length == 0 ? <label className="search-label">Search this manual</label> : null}
          <div className="search-item-wrapper">
            <input
              enterKeyHint="search"
              className="search-input"
              type="search"
              onChange={(element) => {
                setSearch(element.target.value);
              }}
            ></input>
            <div className="search-submit-wrapper">
              <button
                className="search-submit"
                type="submit"
                enterKeyHint="search"
                onClick={(event) => {
                  event.preventDefault();
                  let results = props.GetSearchResults(search);
                  navigation("/Search/", { state: { searchResults: results } });
                }}
              >
                <svg
                  className="search-icon"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                >
                  <circle cx="12.0161" cy="11.0161" r="8.51613" stroke="currentColor" strokeWidth="3"></circle>
                  <line x1="17.8668" y1="17.3587" x2="26.4475" y2="25.9393" stroke="currentColor" strokeWidth="3"></line>
                </svg>
                "Search"
              </button>
            </div>
          </div>
        </div>
      </form>
      <h2>{location.state.searchResults.length} results</h2>
      <ul className="gem-c-document-list">
        {location.state.searchResults.map((result) => {
          const navigationDetails = GetNavigationDetails(result.navigation);
          return (
            <li className="gem-c-document-list__item">
              <Link to={navigationDetails.link} className="gem-c-document-list__item-title">
                {navigationDetails.title}
              </Link>
              <p>{navigationDetails.details}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SearchResults;
