import React from "react";

function P4_glossary() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li"><b>Data attribute</b>:  Information about an entity in a dataset, in this case an infrastructure asset. If an asset is a row, an attribute is a column.</li>
        <li className="content-li"><b>Data format</b>: The syntax, encoding, file format or media type for storing or transmitting your data.</li>
        <li className="content-li"><b>Dataset owner</b>: The person/ team/ organisation that manages the dataset. This could be an asset owner, local authority team member or a third-party organisation.</li>
        <li className="content-li"><b>DCIA</b>:  Digital Connectivity Infrastructure Accelerator</li>
        <li className="content-li"><b>Digital asset platform</b>:  GIS platform where local authorities can upload their assets and create an opportunity for industry users to use those assets.</li>
        <li className="content-li"><b>Encryption</b>: A process that takes information and converts it into a code that is not intuitively understandable by a person or representative of the original information.</li>
        <li className="content-li"><b>GIS</b>:  A Geographic Information System (GIS) is a system to store, capture, analyse, manage, edit and visualise geographic data.</li>
        <li className="content-li"><b>Sanitation</b>: A process that takes information and converts it into a code that is not intuitively understandable by a person or representative of the original information.</li>
      </ul>

    </div>
  );
}

export default P4_glossary;
