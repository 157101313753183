import React from "react";

function P7bi(props) {
  return (
    <div className="content-div">
      <p className="content-p">Automated data transfer pipelines use Application Programming Interface (API) integrations to transfer data directly from your source system to your platform provider. Required data cleaning steps can be built into the pipeline.</p>
      <p className="content-p">To build a pipeline, your source system must have an API for exporting data to other systems. Your platform provider must also have an API to import your data.</p>
      <p className="content-p">Before you commit to building a data transfer pipeline, discuss this option with your platform provider. Consider how much time each of your organisations would save by automating data transfer.</p>

      <h3 className="content-h3">Advantages</h3>
      <p className="content-p">
        The main advantages of data transfer pipelines are reduced human effort to complete the process and higher consistency and security of data.
      </p>
      <ul className="content-ul">
        <li className="content-li">
          Data transfer takes place automatically without recurrent human intervention. 
        </li>
        <li className="content-li">
          Data security set-up can be tailored to high levels, reducing the vulnerability of the data.
        </li>
        <li className="content-li">
          Incorporating data cleaning processes into the pipeline ensures that data is consistent, streamlining platform providers’ further data processing.
        </li>
      </ul>

      <h3 className="content-h3">Disadvantages</h3>
      <p className="content-p">The main disadvantages are related to cost and the skills needed for implementation.</p>
      <ul className="content-ul">
        <li className="content-li">
          Building, setting up and maintaining the pipeline require specialist technical skills. Your organisation will need access to such skills, either in-house or via outsourcing to a third party. 
        </li>
        <li className="content-li">
          The initial build and implementation of the pipeline is likely to be costly. The cost will vary depending on the required complexity of the pipeline. There will also be an ongoing cost associated with maintaining the pipeline, such as implementing modifications if data requirements change over time.
        </li>
      </ul>
    </div>
  );
}

export default P7bi;
