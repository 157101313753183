import React from "react";

function P4ci() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li">
          <b className="content-b">Time delays –</b> Delays in responding to
          emails are common. Send requests for data as soon as possible and
          build buffer time into your project plan.
        </li>
        <li className="content-li">
          <b className="content-b">Missing attribute information –</b> After you
          receive data from dataset owners, you may find that attribute
          information is missing. Be sure to use the data request template so
          that stakeholders are clear on what information you need. Focus on
          mandatory data requirements first.
        </li>
        <li className="content-li">
          <b className="content-b">Lack of buy-in –</b> Dataset owners are
          likely going to be stretched for time. They might see this project as
          an add-on to their day-to-day role. To overcome this, explain why the
          local authority is undertaking the process and the benefits it will
          bring.
        </li>
      </ul>
    </div>
  );
}

export default P4ci;
