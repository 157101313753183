import React from "react";

function P4fi() {
  return (
    <div className="content-div">
      <p className="content-p">
        To reduce risks to physical asset security, it is important to ensure
        the security of asset data in digital systems. Consider the following
        principles for data security:
      </p>
      <ol className="content-ol">
        <li className="content-nested-li">
          Know the data you are expecting to receive. Engage with data owners to
          find out what parts of your data request/s are likely to be fulfilled.
          This will allow you to understand what data is most sensitive,
          identify risks and apply relevant protection. Avoid collecting
          unnecessary information
        </li>
        <li className="content-nested-li">
          Data needs to be protected at rest. Engage with your IT department to
          learn about the data storage options available in your organisation.
          Consider:
          <ol className="content-nested-ol">
            <li className="content-nested-li">
              The presence of physical and logical access controls so that only
              authorised users can access, modify and/or use the data. For
              example, devices should be always kept in an appropriate
              room/building with controlled access and locked with user
              authentication (e.g. including multifactor authentication).
            </li>
            <li className="content-nested-li">
              Disk encryption using standardised algorithms where data is stored
              on physical devices, such as laptops or removable media. Limit the
              number of physical devices where data is stored to the absolute
              minimum necessary for your organisations’ purposes.
            </li>
            <li className="content-nested-li">
              File encryption to keep data safe when the device is active.
            </li>
            <li className="content-nested-li">
              Consolidation of data where possible. If there is a requirement
              for data to be replicated or cached, minimise the number of copies
              and ensure all are protected. Dispersed datasets, such as files on
              users' desktops, are more vulnerable.
            </li>
            <li className="content-nested-li">
              Appropriately protected virtual machines or virtual storage
              locations, which minimise physical security risks.
            </li>
          </ol>
        </li>
        <li className="content-nested-li">
          Data access interfaces should be well-defined, exposing only the
          necessary amount of data to the relevant people. Consider if different
          permissions are needed for different people within your organisation.
        </li>
        <li className="content-nested-li">
          Use a cloud service or an offline location for backing up data.
        </li>
        <li className="content-nested-li">
          Sanitise storage media when you no longer need it. Your organisation
          should have a policy for the re-use, repair, disposal and destruction
          of storage media and any devices that could store data. This includes
          not only the data itself, but labels that indicate ownership of the
          device or the nature of data contained. You might need support from
          your IT department to successfully carry out this process.
        </li>
      </ol>
      <p className="content-p">
        Keep in mind that storing and working with data will require human
        intervention. Consider how well your security processes integrate with
        your existing ways of working. It is important that users receive
        suitable training and support to minimise errors, but also avoid
        frustration and attempts to bypass processes.
      </p>

      <p className="content-p">
        These principles are based on security guidance from the National Cyber
        Security Centre (NCSC) and the Centre for the Protection of National
        Infrastructure (CPNI). For further details, please refer to these
        organisations’ websites. Engage with your internal IT department to
        understand your existing security processes and measures.
      </p>
    </div>
  );
}

export default P4fi;
