import React from "react";

function P2_glossary() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li">
          <b>Data attribute</b>: Information about an entity in a dataset, in this case an asset. If an asset is a row, an attribute is a column.
        </li>
        <li className="content-li">
          <b>DCIA</b>: Digital Connectivity Infrastructure Accelerator 
        </li>
        <li className="content-li">
          <b>Digital asset platform</b>: GIS platform where local authorities can upload their assets and create an opportunity for industry users to use those assets. 
        </li>
      </ul>
    </div>
  );
}

export default P2_glossary;
