import React from "react";

function P3bi() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li">
          Discover what datasets are available within your area. This could include both asset datasets and local contextual datasets, such as conservation areas or nature reserves.
        </li>
        <li className="content-li">Identify key stakeholders within the data ecosystem and the relationships between them.</li>
        <li className="content-li">Identify gaps in information across the datasets and where changes are needed. </li>
        <li className="content-li">
          Help gain buy-in for this project and collaborate with stakeholders. Discussing datasets can be confusing for people who are not knowledgeable in the area. Presenting the information visually makes it more easily understandable, which helps with buy-in for new digital projects.
        </li>
        <li className="content-li">
          Highlight opportunities to improve and optimise the data. For example, the data ecosystem map visualises the number of systems and data formats that are currently in use, which shows the effort needed to aggregate datasets at a high level.
        </li>
        <li className="content-li">
          Document where you are currently. In the future, you will easily see if current data practices have improved.
        </li>
        <li className="content-li">
          Documenting your data landscape makes it easier to create or adjust an organisational or project-level data strategy.
        </li>
        <li className="content-li">
          Documenting your data landscape enables knowledge to persist as roles and responsibilities change. Key knowledge could be lost if it is not documented, which will make it more difficult to progress in the area.
        </li>
        <li className="content-li">
          The data ecosystem map can act as a single source of truth for available information. Stakeholders across the organisation will be able to leverage this knowledge in their own work.
        </li>
      </ul>
    </div>
  );
}

export default P3bi;
