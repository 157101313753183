import React from "react";

function P4ei() {
  return (
    <div className="content-div">
      <p className="content-p">
      If your asset data is publicly available, you may assume your data is neither sensitive nor a source of potential security risks. However, combining this asset data with information on existing attachments, such as CCTV cameras or existing small cells, creates security risks.
      </p>
      <p className="content-p">
      Certain segments of the public have a negative sentiment towards 5G. If these actors can link asset locations with data on 5G infrastructure attachments, the assets will be at risk of vandalism. In a worst-case scenario, threat actors may aggregate this information to plan attacks on infrastructure.
      </p>

      <p className="content-p">
      To lower the risk of vandalism, ensure that asset locations are stored separately from their existing attachments. 
      </p>
    </div>
  );
}

export default P4ei;
