import React from "react";

function P4ai() {
  return (
    <div className="content-div">
      <p className="content-p">
        These data attribute recommendations emerged from collaborations between local authorities, digital asset platform providers and industry stakeholders. However, platform providers may have different mandatory and recommended requirements. Use the list below as a starting point for discussions with your own platform provider.
      </p>

      <p className="content-p">
        You may hold data on street lighting and CCTV columns according to PAS 190, a draft specification for assessment of columns for multi-functional use. Not all attributes defined in PAS 190 are necessary for a digital asset platform.
      </p>

      <h3 className="content-h3">Mandatory</h3>
      <p className="content-p">
        An asset cannot be uploaded to a digital asset platform without the following pieces of information:
      </p>
      <table class="govuk-table">
        <thead class="govuk-table__head">
          <tr class="govuk-table__row">
            <th scope="col" class="govuk-table__header">
              Data attribute
            </th>
            <th scope="col" class="govuk-table__header">
              Why this information is needed
            </th>
            <th scope="col" class="govuk-table__header">
              Recommended format
            </th>
          </tr>
        </thead>
        <tbody class="govuk-table__body">
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Location
            </th>
            <td class="govuk-table__cell">
              Where is it? <br />
              Industry users need to know the precise location of the asset. They will use an asset’s location to understand how proposed infrastructure will affect connectivity in an area.
            </td>
            <td className="govuk-table__cell">
              (Latitude and Longitude) or (Easting and Northing). Postcodes will not work as they do not provide precise enough information.
            </td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Asset type
            </th>
            <td class="govuk-table__cell">
              What is it? <br />
              Industry users need to ensure that the asset is suitable for connectivity infrastructure.
            </td>
            <td className="govuk-table__cell">Text – ideally aligned with Ordnance Survey AddressBase classification codes</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Unique ID
            </th>
            <td class="govuk-table__cell">
              How can an asset be uniquely identifiable?
              <br />
              The asset must have a unique ID to ensure that all stakeholders are referring to the same asset during communications. It is also needed if asset information is updated: any changes need to be linked to the correct asset in the platform database.
            </td>
            <td className="govuk-table__cell">Text or numbers – can be a Unique Property Reference Number (UPRN) for building assets, or a column number for street lighting and CCTV columns</td>
          </tr>
        </tbody>
      </table>

      <h3 className="content-h3">Recommended</h3>
      <p className="content-p">
        An asset can be uploaded to a digital asset platform without the attributes below. However, providing this information will streamline communications with industry users.
      </p>
      <table class="govuk-table">
        <thead class="govuk-table__head">
          <tr class="govuk-table__row">
            <th scope="col" class="govuk-table__header">
              Data attribute
            </th>
            <th scope="col" class="govuk-table__header">
              value to users
            </th>
            <th scope="col" class="govuk-table__header">
              Recommended format
            </th>
          </tr>
        </thead>
        <tbody class="govuk-table__body">
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Asset owner contact
            </th>
            <td class="govuk-table__cell">
              Who to contact about ownership?
              Industry users need a direct point of contact for enquiries about an asset.
            </td>
            <td className="govuk-table__cell">Text - email address</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Height
            </th>
            <td class="govuk-table__cell">
              Most connectivity infrastructure is deployed at a certain height for signal reasons. Industry users incorporate asset height into models of network connectivity.
            </td>
            <td className="govuk-table__cell">Metres</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Address
            </th>
            <td class="govuk-table__cell">
              Some platform providers may use the asset address as an additional attribute to make platform navigation easier for industry users.
            </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Site access details (e.g., special procedures to access the site for maintenance)
            </th>
            <td class="govuk-table__cell">
              Industry users need 24/7 access to their infrastructure in case there is a fault that needs fixing. They need to know if a site is not always accessible or needs special procedures for access.
            </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Power supply availability status (Yes / No)
            </th>
            <td class="govuk-table__cell">
              Industry users to understand if an asset has an existing power supply that can be used for connectivity infrastructure.
            </td>
            <td className="govuk-table__cell">Yes/No</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Power supply availability details (e.g., any asset-specific information that the asset owner believes is relevant to understanding how easily power could be provided to equipment utilising this asset)
            </th>
            <td class="govuk-table__cell">
              Industry users need to know if power supply is 24/7. Additionally, if an asset is not already connected to the power network, they need to know how easily that could be done.
            </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Backhaul accessibility status (Yes /No)
            </th>
            <td class="govuk-table__cell">
              Industry users need to understand if the asset is already connected to fixed telecommunication infrastructure.
            </td>
            <td className="govuk-table__cell">Yes/No</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Backhaul accessibility details (e.g., any asset-specific information that the asset owner believes is relevant to understanding how easily equipment utilising this asset could access fixed telecommunication infrastructure such as fibre)
            </th>
            <td class="govuk-table__cell">
              If an asset is not already connected to telecommunications infrastructure, industry users need to know how easily that could be done.
            </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Site availability date
            </th>
            <td class="govuk-table__cell">Industry users need to understand when a site/ asset is available for their use.</td>
            <td className="govuk-table__cell">(Day/ Month/ Year)</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Date when dataset was last updated
            </th>
            <td class="govuk-table__cell">
              Industry users need to understand when the dataset was last updated. This indicates the likely accuracy of the dataset.
            </td>
            <td className="govuk-table__cell">(Day/ Month/ Year)</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Special implementation issue details (e.g., any other asset-specific information that the asset owner believes is relevant to know when selecting this asset not covered elsewhere)
            </th>
            <td class="govuk-table__cell">
              Industry users need to understand if there are any other factors that might influence their decisions about an asset.
            </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
          <tr class="govuk-table__row">
            <th scope="row" class="govuk-table__header">
              Site asset deployment potential category (e.g., potential macrosite, potential microsite)
            </th>
            <td class="govuk-table__cell">Industry users need to understand if the site is suitable for the types of infrastructure they need to deploy. </td>
            <td className="govuk-table__cell">Text</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default P4ai;
