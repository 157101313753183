import React from "react";
import pseudo from "../Code-assets/section-6/pseudo_data_validation";
import pystuff from "../Code-assets/section-6/data_validation";
import { CopyBlock, dracula } from "react-code-blocks";
import VersionDownload from "../Components/VersionDownload";
import Download from "../Components/Download";

function P6di() {
  return (
    <div className="content-code-container">
      <p className="content-p">
        Data accuracy evaluation is a time intensive process. If you have in-house technical skills, you may wish to build an automated data accuracy evaluation pipeline for the following benefits:
      </p>
      <ul className="content-ul">
        <li className="content-li">To reduce the workload and improve speed and efficiency of data validation process</li>
        <li className="content-li">To make the validation process reusable and consistent across multiple datasets, and the results comparable</li>
      </ul>
      <p className="content-p">
        Many programming languages can be used to develop the data validation pipeline. It is up to you and your organisation to decide which language works best given in-house skill sets.
      </p>
      <p className="content-p">
        The aim of the tools in this section is not to provide snippets of code that can be directly plugged into your existing workflows and validate the data for you. Rather, the purpose is to demonstrate the logic of steps in data validation and considerations for your code. 
      </p>
      <p className="content-p">You will need programming experience to use the following sections</p>

      <h3 className="content-h3">Pseudocode/Code</h3>
      <p>
        Our tool presents the pseudocode in a language-agnostic format to demonstrate the process, along with sample Python code to show how the
        pseudocode can be coded in a popular programming language.
      </p>
      <p className="content-p">
        Our tool presents the pseudocode in a language-agnostic format to demonstrate the process, along with sample Python code to show how the pseudocode can be coded in a popular programming language
      </p>
      <p className="content-p">
      For the sample code, Python and a Python library Selenium are used to automate repetitive steps within the workflow. For each asset, the coordinates are automatically sent to the web browser to be searched on Google Maps. You will be presented with the address and type of the asset, then asked to confirm if the location is valid and add comments regarding additional information. This information is stored in memory and the process iterates to the next asset for validation. On completion the validation data is written to a file, so that the results can be analysed.
      </p>
    
      <br />
      
      <VersionDownload 
        download_titles = {["Pseudocode", "Python"]}
        download_options = {[
          <Download
          content={{ title: "Psuedo Data Validation", details: "Plain text file, 890 B", description: "" }}
          url={"/Download-files/Heading 6 (checking the accuracy of your data)/Code_n_pseudocode/pseudo_data_validation.txt"}
          ></Download>,
          <Download
          content={{ title: "Data Validation", details: "Python script, 3 KB", description: "" }}
          url={"/Download-files/Heading 6 (checking the accuracy of your data)/Code_n_pseudocode/data_validation.py"}
          ></Download>
        ]}
      />
    </div>
  );
}

export default P6di;
