import React from "react";

function P4bi() {
  return (
    <div className="content-div">
      <p className="content-p">
        Digital asset platform providers generally accept all digital data formats: comma-separated value (CSV) files, shapefiles, or GeoJSON files. Your own platform provider may prefer one format over the others. If so, ensure that asset dataset owners provide their data in your provider’s preferred format.
      </p>
      <p className="content-p">
        PDFs and Word documents cannot be integrated into digital asset platforms.
      </p>
    </div>
  );
}

export default P4bi;
