import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import pseudo from "../Code-assets/section-5/pseudo_deduplication.js";
import pystuff from "../Code-assets/section-5/code - deduplication";
import VersionDownload from "../Components/VersionDownload.js";
import Download from "../Components/Download.js";

function P5ci() {
  return (
    <div className="content-code-container">
      <p className="content-p">The pseudocode file demonstrates the logic of the complex deduplication process, which you can apply in any programming language. The sample Python code shows how the deduplication logic can be applied in Python.</p>

      <VersionDownload 
        download_titles = {["Pseudocode", "Python"]}
        download_options = {[
          <Download
          content={{ title: "Psuedo Deduplication", details: "Plain text file, 2 KB", description: "" }}
          url={"/Download-files/Heading 5 (cleaning your data)/Code_n_pseudocode/pseudo_deduplication.txt"}
          ></Download>,
          <Download
          content={{ title: "Code Deduplication", details: "Python script, 2 KB", description: "" }}
          url={"/Download-files/Heading 5 (cleaning your data)/Code_n_pseudocode/code - deduplication.py"}
          ></Download>
        ]}
      />
    </div>
  );
}
export default P5ci;
