import React from "react";

function P1ci() {
  return (
    <div className="content-div">
      <p className="content-p">
      The sections are in chronological order based on the expected steps in a data integration process. If you have already undertaken some activities, you can navigate to activities you have not yet completed. This toolkit is designed to help you find the most relevant information to your role at any point in the journey.
      </p>
    </div>
  );
}

export default P1ci;
