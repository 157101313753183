import React from "react";
import { useParams } from "react-router-dom";
import ContentDict from "../Content-Pages/ContentDict";
import Breadcrumbs from "./Breadcrumbs";
import "../Content-Pages/Content.scss";

function ContentPage(props) {
  let params = useParams();
  return (
    <div>
      <Breadcrumbs page={`Home/Details/${params.id}/${params.x}/${params.y}`} />
      {ContentDict[`${params.id}/${params.x}/${params.y}`]}
    </div>
  );
}

export default ContentPage;
