import React from "react";

function P5bi() {
  return (
    <div className="content-div">
      <p className="content-p">You can clean your asset data using any tools available to you, such as desktop-based spreadsheet software, GIS software, or a database management system. Please note that to enrich your asset data using point-in-polygon operations, you need to use tools with GIS capabilities. </p>
      <h3 className="content-h3">Why to automate cleaning</h3>
      <p className="content-p">
        If you have strong in-house technical skills, you may wish to automate all or part of the data cleaning process. 
      </p>
      <ul className="content-ul">
        <li className="content-li">The data cleaning process involves multiple datasets and complex data transformation and manipulation procedures. Doing this manually is resource-intensive and error-prone.</li>
        <li className="content-li">Depending on how often new assets are built or existing assets are altered, you will need to periodically re-upload data to your digital asset platform. It is important to keep cleaning procedures consistent across data uploads. An automated cleaning pipeline helps maintain consistency.</li>
        <li className="content-li">The source data format from the asset owners and the target format required by platform providers are unlikely to change. This minimises the ongoing maintenance requirements of an automated cleaning pipeline.</li>
      </ul>

      <h3 className="content-h3">Tools for automating the workflow</h3>
      <p className="content-p">If you have a good level of programming experience, use the downloadable files under ‘Resources’ to help you start planning your code.</p>
    </div>
  );
}

export default P5bi;
