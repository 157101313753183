import React from "react";

function P5_intro() {
  return (
    <div className="content-div">
      <h3 className="content-h3">This section is relevant to:</h3>
      <ul className="content-ul">
        <li className="content-li">Data processors</li>
        <li className="content-li">Digital coordinators/Data champions</li>
      </ul>
    </div>
  );
}

export default P5_intro;
