import React from "react";
import slide1 from "../Images/Slide1.jpeg";
import slide2 from "../Images/Slide2.jpeg";
import slide3 from "../Images/Slide3.jpeg";
import slide4 from "../Images/Slide4.jpeg";
import slide5 from "../Images/Slide5.jpeg";

function P5ai(props) {
  return (
    <div className="content-div">
      <h3 className="content-h3">Filter out assets missing mandatory information</h3>
      <p className="content-p">
        Remove any assets missing unique IDs and coordinates from the dataset you are planning to send to your platform provider. Platform providers
        are unable to store and display these assets.
      </p>
      <div className="content-img-container">
        <img className="content-img" src={slide1} style={{ width: "100%" }} />
      </div>

      <h3 className="content-h3"> Deduplicate assets by location</h3>
      <p className="content-p">Duplicated data items are a common issue. Duplicates can occur in the following situations:</p>
      <ul className="content-ul">
        <li>when the data is entered manually, </li>
        <li>when multiple data sources are aggregated together, or</li>
        <li>when a multi-use building (such as a combined library and leisure centre) is listed once for each use, but with the same coordinates.</li>
      </ul>
      <p className="content-p">
        To avoid problems with asset negotiations or future data updates, remove all but one asset associated with any duplicated ID or set of
        coordinates.
      </p>
      <p className="content-p">
        There may be a valid reason for multiple assets having the same coordinates, such as parts of a site large enough to host separate macrocells.
        However, you can clarify these details in further communications with industry users.
      </p>

      <div className="content-img-container">
        <img className="content-img" src={slide2} style={{ width: "100%" }} />
      </div>

      <h3 className="content-h3">Identify suitability of asset for connectivity infrastructure deployment</h3>
      <p className="content-p">
        Your dataset is likely to include assets that are unsuitable for connectivity infrastructure deployment, such as ponds or war memorials. Local
        telecommunications policies may also forbid the placement of mobile infrastructure on or near certain asset types. These assets should still
        be displayed on the platform, as comprehensiveness increases trust in the dataset. Industry users may also be interested in assets that would
        affect radio propagation.
      </p>
      <div className="content-img-container">
        <img className="content-img" src={slide3} style={{ width: "100%" }} />
      </div>
      <h3 className="content-h3"> Uniquely identify assets</h3>
      <p className="content-p">
        Multiple asset datasets need to be aggregated before the data is uploaded to the platform. During the data aggregation process, an LA-specific
        prefix should be added to the site IDs to reduce the chance of duplicating unique IDs in the aggregated dataset. For example, asset ID “101”
        from Central City Council can be changed to ‘ccc_101’ in the aggregated dataset, where the prefix “ccc_” represents Central City Council.
      </p>
      <div className="content-img-container">
        <img className="content-img" src={slide4} style={{ width: "100%" }} />
      </div>

      <h3 className="content-h3"> Enrich with additional attributes</h3>
      <p className="content-p">
        You can enrich your asset data with information from other datasets. For example, some platform providers classify council-owned assets as
        either “rooftop” or “greenfield” sites. As this classification is intended for an infrastructure deployment use case, asset owners do not
        usually hold this information.
      </p>
      <p className="content-p">
        You can add this classification with the help of additional data sources, such as the Ordnance Survey’s MasterMap Topography Layer or
        OpenStreetMap. You can then use what is known as a point-in-polygon operation. These external datasets contain building polygons. If an
        asset’s coordinates fall within a building polygon, the asset can be classified as “rooftop”; if not, the asset can be classified as
        “greenfield”.
      </p>
      <p className="content-p">
        You can also use this technique to enrich the asset dataset with other types of information. For example, you can add a field identifying
        whether an asset is in a Conservation Area or Site of Special Scientific Interest, and therefore cannot be used for connectivity
        infrastructure.
      </p>
      <div className="content-img-container">
        <img className="content-img" src={slide5} style={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default P5ai;
