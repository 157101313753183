import React from "react";

function P5_glossary() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li">
          <b>Coordinate Reference System (CRS)</b>: A system used to precisely
          measure and locate specific points or areas on the surface of the
          earth via a specific map projection. In practical terms, locations are
          defined by coordinates (i.e. a given set of numbers). Different CRS
          will represent the same location with different numbers.
        </li>
        <li className="content-li">
          <b>Data enrichment</b>: Adding additional information to an existing
          dataset.
        </li>
        <li className="content-li">
          <b>EPSG codes</b>: Originated in the European Petroleum Survey Group,
          EPSG codes define how the location of items is defined on a map.
          Common EPSG codes include:{" "}
        </li>
        <ul className="content-nested-ul">
          <li className="content-li">
            EPSG: 4326 – A WGS 84, latitude/longitude coordinate system based on
            the Earth's centre of mass, used in GPS and other common
            applications.{" "}
          </li>
          <li className="content-li">
            EPSG: 3857 - Web Mercator projection used by many map-based web
            applications, such as OpenStreetMap and Google Maps.
          </li>
          <li className="content-li">
            EPSG: 27700 - British National Grid - United Kingdom Ordnance
            Survey. Used for many UK-specific datasets.
          </li>
        </ul>
      </ul>
    </div>
  );
}

export default P5_glossary;
