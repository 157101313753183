import React from "react";
import Download from "../Components/Download";
import VersionDownload from "../Components/VersionDownload";

function P4gi() {
  return (
    <div className="content-div">
      <p className="content-p">
        Use the attached template to specify what attributes you need from asset
        dataset owners.
      </p>

      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Data request template",
              details: "Word Document, 29 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 4 (Acquiring the data)/MS Office/Data request template.docx"
            }
          />,
          <Download
            content={{
              title: "Data request template",
              details: "ODT Document, 43 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 4 (Acquiring the data)/Libre Office/Data request template.odt"
            }
          />,
          <Download
            content={{
              title: "Data request template",
              details: "ODT Document, 25 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 4 (Acquiring the data)/Apache Open Office/Data request template.odt"
            }
          />,
        ]}
      />
    </div>
  );
}

export default P4gi;
