import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./Breadcrumbs.scss";
import NavigationObject from "../NavigationObject.json";

function Breadcrumbs(props) {
  const location = useLocation();
  const alphabetToNumber = { a: 0, b: 1, c: 2, d: 3, e: 4, f: 5, g: 6 };
  const romanNumeralToNumber = { i: 0, ii: 1, iii: 2 };
  const [relevantPages, setRelevantPages] = useState({});
  var relevantPagesTemp = {};
  var pages = [];
  useEffect(() => {
    //converts the url path to the relevant page directories with links to those directories
    pages = location.pathname.split("/");
    pages = pages.filter((element) => element != "");
    relevantPagesTemp.Home = "/";
    if (pages.length > 0) relevantPagesTemp[NavigationObject.homePage[parseInt(pages[1]) - 1].title] = `/${pages[0]}/${pages[1]}/`;
    if (pages.length > 2) {
      relevantPagesTemp[NavigationObject.homePage[parseInt(pages[1]) - 1].sections[alphabetToNumber[pages[2]]].title] = `/${pages[0]}/${pages[1]}`;
      relevantPagesTemp[
        NavigationObject.homePage[parseInt(pages[1]) - 1].sections[alphabetToNumber[pages[2]]].links[romanNumeralToNumber[pages[3]]].title
      ] = `/${pages[0]}/${pages[1]}/${pages[2]}/${pages[3]}`;
    }

    setRelevantPages(relevantPagesTemp);
  }, [location.pathname]);
  return (
    <div className="govuk-breadcrumbs">
      <ol className="govuk-breadcrumbs__list">
        {relevantPages
          ? Object.keys(relevantPages).map((page) => {
              return (
                <li className="govuk-breadcrumbs__list-item" key={page}>
                  <Link className="govuk-breadcrumbs__link" to={relevantPages[page]}>
                    {page}
                  </Link>
                </li>
              );
            })
          : null}
      </ol>
    </div>
  );
}

export default Breadcrumbs;
