import React from "react";

function P1_intro() {
  return (
    <div className="content-div">
      <h3 className="content-h3">Introduction to toolkit</h3>
      <br />
      <a className="content-p">
        If your organisation is committed to adopting a digital asset platform
        to manage demand for connectivity infrastructure deployment, use this
        toolkit to help you prepare your asset data for the platform.
        <br />
        <br />
        <i>Background</i>
        <br />
        <br />
        New digital platforms have emerged to help open up public sector assets
        for the rollout of advanced connectivity infrastructure. These digital
        asset platforms map publicly owned assets that could potentially host
        macrocells or small cells. Telecommunications industry users use these
        platforms to view the assets and communicate directly with asset owners,
        streamlining existing site acquisition processes.
        <br />
        <br />
        To realise the benefits of these platforms, local authorities must
        provide data on their assets to a digital asset platform provider. This
        can be a slow and difficult process that requires coordinating many
        stakeholders. Different asset classes are held by different council
        departments, meaning asset datasets are stored across many systems and
        vary in quality.
        <br />
        <br />
        <i>About the toolkit</i>
        <br />
        <br />
        Commissioned by the Department for Science, Innovation, and Technology
        (DSIT), this data integration toolkit is a resource to help your
        organisation integrate asset data into a digital asset platform. This
        toolkit synthesises data discovery and integration learnings from DSIT’s{" "}
        <a href="https://www.gov.uk/guidance/digital-connectivity-infrastructure-accelerator-programme">
          Digital Connectivity Infrastructure Accelerator
        </a>{" "}
        (DCIA) pilots, which trialled digital asset platforms in eight UK
        regions from January 2022 to March 2023. The toolkit will enable you to
        prepare data in line with best practices that emerged from these pilots
        and are endorsed by DSIT.
        <br />
        <br />
        The toolkit is intended for a broad range of stakeholders within your
        organisation, all with different needs and different levels of data
        maturity.
        <br />
        <br />
      </a>
    </div>
  );
}

export default P1_intro;
