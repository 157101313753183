import P1_glossary from "./P1_glossary";
import P1_intro from "./P1_intro";
import P2_glossary from "./P2_glossary";
import P2_intro from "./P2_intro";
import P3_glossary from "./P3_glossary";
import P3_intro from "./P3_intro";
import P4_glossary from "./P4_glossary";
import P4_intro from "./P4_intro";
import P5_glossary from "./P5_glossary";
import P5_intro from "./P5_intro";
import P6_glossary from "./P6_glossary";
import P6_intro from "./P6_intro";
import P7_glossary from "./P7_glossary";
import P7_intro from "./P7_intro";
import P8_glossary from "./P8_glossary";
import P8_intro from "./P8_intro";
import P1ai from "./P1ai";
import P1bi from "./P1bi";
import P1ci from "./P1ci";
import P2ai from "./P2ai";
import P2bi from "./P2bi";
import P2ci from "./P2ci";
import P3ai from "./P3ai";
import P3bi from "./P3bi";
import P3ci from "./P3ci";
import P3di from "./P3di";
import P3ei from "./P3ei";
import P4ai from "./P4ai";
import P4bi from "./P4bi";
import P4ci from "./P4ci";
import P4di from "./P4di";
import P4ei from "./P4ei";
import P4fi from "./P4fi";
import P4gi from "./P4gi";
import P5ai from "./P5ai";
import P5bi from "./P5bi";
import P5ci from "./P5ci";
import P6ai from "./P6ai";
import P6bi from "./P6bi";
import P6ci from "./P6ci";
import P6di from "./P6di";
import P7ai from "./P7ai";
import P7bi from "./P7bi";
import P7ci from "./P7ci";
import P8ai from "./P8ai";
import P8bi from "./P8bi";
import P8ci from "./P8ci";
import P8di from "./P8di";
import P8ei from "./P8ei";
import P8fi from "./P8fi";

const ContentDict = {
  "1_glossary": <P1_glossary />,
  "1_intro": <P1_intro />,
  "2_glossary": <P2_glossary />,
  "2_intro": <P2_intro />,
  "3_glossary": <P3_glossary />,
  "3_intro": <P3_intro />,
  "4_glossary": <P4_glossary />,
  "4_intro": <P4_intro />,
  "5_glossary": <P5_glossary />,
  "5_intro": <P5_intro />,
  "6_glossary": <P6_glossary />,
  "6_intro": <P6_intro />,
  "7_glossary": <P7_glossary />,
  "7_intro": <P7_intro />,
  "8_glossary": <P8_glossary />,
  "8_intro": <P8_intro />,
  "1/a/i": <P1ai />,
  "1/b/i": <P1bi />,
  "1/c/i": <P1ci />,
  "2/a/i": <P2ai />,
  "2/b/i": <P2bi />,
  "2/c/i": <P2ci />,
  "3/a/i": <P3ai />,
  "3/b/i": <P3bi />,
  "3/c/i": <P3ci />,
  "3/d/i": <P3di />,
  "3/e/i": <P3ei />,
  "4/a/i": <P4ai />,
  "4/b/i": <P4bi />,
  "4/c/i": <P4ci />,
  "4/d/i": <P4di />,
  "4/e/i": <P4ei />,
  "4/f/i": <P4fi />,
  "4/g/i": <P4gi />,
  "5/a/i": <P5ai />,
  "5/b/i": <P5bi />,
  "5/c/i": <P5ci />,
  "6/a/i": <P6ai />,
  "6/b/i": <P6bi />,
  "6/c/i": <P6ci />,
  "6/d/i": <P6di />,
  "7/a/i": <P7ai />,
  "7/b/i": <P7bi />,
  "7/c/i": <P7ci />,
  "8/a/i": <P8ai />,
  "8/b/i": <P8bi />,
  "8/c/i": <P8ci />,
  "8/d/i": <P8di />,
  "8/e/i": <P8ei />,
  "8/f/i": <P8fi />
};

export default ContentDict;
