import React from "react";

function P8_intro() {
  return (
    <div className="content-div">
      <h3 className="content-h3">The section is relevant to</h3>
      <ul className="content-ul">
        <li className="content-li">Digital coordinators / Data champions</li>
        <li className="content-li">Data processors</li>
        <li className="content-li">Asset owners</li>
        <li className="content-li">Senior local authority stakeholders</li>
      </ul>
    </div>
  );
}

export default P8_intro;
