import React from "react";
import Download from "../Components/Download";
import VersionDownload from "../Components/VersionDownload";

function P3ei() {
  return (
    <div className="content-div">
      <p className="content-p">
        The attached templates can help you collect and document information
        about your local data ecosystem.
      </p>
      <h3 className="content-h3">Ecosystem map questionnaire template</h3>
      <p className="content-p">
        This template provides a set of questions about the source system,
        format, ownership, accessibility, and update frequency of relevant
        datasets.
      </p>
      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Ecosystem map questionaire template",
              details: "Word file, 25 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/MS Office/Ecosystem map questionnaire template.docx"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map questionaire template",
              details: "ODT Document, 26 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Libre Office/Ecosystem map questionnaire template.odt"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map questionaire template",
              details: "ODT Document, 18 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Apache Open Office/Ecosystem map questionnaire template.odt"
            }
          ></Download>,
        ]}
      />

      <h3 className="content-h3">Ecosystem map table template</h3>
      <p className="content-p">
        This template provides a table structure to hold the information you
        collect about your data ecosystem.
      </p>
      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Ecosystem map table template",
              details: "Excel Spreadsheet, 27 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/MS Office/Ecosystem map table template.xlsx"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map table template",
              details: "ODS Spreadsheet, 20 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Libre Office/Ecosystem map table template.ods"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map table template",
              details: "ODS Spreadsheet, 17 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Apache Open Office/Ecosystem map table template.ods"
            }
          ></Download>,
        ]}
      />

      <h3 className="content-h3">Ecosystem map template (macro enabled)</h3>
      <p className="content-p">
        You can use this template to create a data ecosystem map that presents
        information about your data visually. The macro-enabled version of the
        file provides a smoother user experience when building your own
        ecosystem map. If you cannot use macro-enabled files in your
        organisation consider discussing its use with your IT department or
        download the "no macros" version of the file.
      </p>
      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Ecosystem map template",
              details: "Excel Spreadsheet, 245 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/MS Office/Ecosystem map template (macro enabled).xlsm"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map template",
              details: "ODS Spreadsheet, 185 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Libre Office/Ecosystem map template (macro enabled).ods"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map template",
              details: "ODS Spreadsheet, 181 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Apache Open Office/Ecosystem map template (macro enabled).ods"
            }
          ></Download>,
        ]}
      />

      <h3 className="content-h3">Ecosystem map template (non-macro enabled)</h3>
      <p className="content-p">
        You can use this template to create a data ecosystem map that presents
        information about your data visually. These files do not have macros.
      </p>
      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Ecosystem map template (no macros)",
              details: "Excel Spreadsheet, 220 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/MS Office/Ecosystem map template (no macros).xlsx"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map template (no macros)",
              details: "ODS Spreadsheet, 185 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Libre Office/Ecosystem map template (no macros).ods"
            }
          ></Download>,
          <Download
            content={{
              title: "Ecosystem map template (no macros)",
              details: "ODS Spreadsheet, 178 KB",
              description:
                "This file may not be suitable for user of assistive technology",
            }}
            url={
              "/Download-files/Heading 3 (mapping your data ecosystem map)/Apache Open Office/Ecosystem map template (no macros).ods"
            }
          ></Download>,
        ]}
      />
    </div>
  );
}

export default P3ei;
