import React from "react";
import img2ai from "../Images/2ai.png";
function P2ai() {
  return (
    <div className="content-div">
      <p className="content-p">
        The timelines provided are an estimate. Every situation is different, and every local authority is at a different level of data readiness. If
        you are starting from the beginning, it will likely take around 3-6 months to get your infrastructure asset data ready for a digital asset
        platform. The timeline is likely to be shorter if you have previously undertaken digitisation initiatives like this one.
      </p>
      <p className="content-p">
        The diagram below estimates how long each data integration stage is likely to take. The tasks within these stages are documented in detail in
        other sections.
      </p>

      <p className="content-p">
        As new datasets or information become available, you will likely have to revisit previous stages. The process will continue after you have
        uploaded the asset data on a digital asset platform, as you will have to refresh your data, manage tasks, and respond to requests.
      </p>

      <div className="content-img-container">
        <img className="content-img" src={img2ai} style={{ width: "70%" }} />
      </div>
    </div>
  );
}

export default P2ai;
