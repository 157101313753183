import React from "react";

function P3ci() {
  return (
    <div className="content-div">
      <p className="content-p">
        Mobile and wireless telecommunications infrastructure can be deployed on many different classes of assets. Land assets or buildings can host macrocells, while street furniture assets can host small cells. Listed below are the asset classes that telecommunications industry users are most interested in.
      </p>
      <ul className="content-ul">
        <li className="content-li">Buildings</li>
        <li className="content-li">Land</li>
        <li className="content-li">Street lighting columns</li>
        <li className="content-li">CCTV columns</li>
      </ul>
      <p className="content-p">
        Other street furniture asset types, such as bus shelters, gantry signs, and traffic signals, can host small cells as well. However, these asset classes are lower in priority than street lighting and CCTV columns.
      </p>
    </div>
  );
}

export default P3ci;
