import React from "react";
import { Link } from "react-router-dom";
import "../Components/Home.scss";

function ContentsButton(props) {
  return (
    <div className="breadcrumbs">
      <ol className="breadcrumbs__list">
        <li className="breadcrumbs__list-item">
          <Link to={"../"}>Home</Link>
        </li>
      </ol>
    </div>
  );
}

export default ContentsButton;
