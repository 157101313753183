import React from "react";

function P1bi() {
  return (
    <div className="content-div">
      <p className="content-p">
        This toolkit is for everyone involved in the process of preparing local authority asset data for a digital asset platform. The roles listed
        below are most likely to benefit from using the toolkit. Please note that this is not an exhaustive list, and some roles will overlap with
        others.
      </p>

      <p className="content-p">
        <b className="content-b">Digital coordinator/ Data champion</b>
      </p>
      <p className="content-p">
        You are responsible for coordinating data and digital initiatives in a local authority. In this project, you are securing stakeholder buy-in
        and collecting asset data from relevant stakeholders. This toolkit will help you understand the data ecosystem and plan the preparation of
        asset data for your digital asset platform.
      </p>
      <p className="content-p">
        <b className="content-b">Data processor </b>
      </p>
      <p className="content-p">
        You are responsible for managing and cleaning asset data to your digital asset platform provider’s requirements. This toolkit will help you
        understand how to clean and validate data according to these requirements, then transfer data securely and efficiently to your platform
        provider.
      </p>
      <p className="content-p">
        <b className="content-b">Asset owner</b>
      </p>
      <p className="content-p">
        You are the owner of an asset that may be suitable for connectivity infrastructure deployment. This toolkit will help you understand why
        industry users are interested in your assets and explain actions and requests you are likely to face.
      </p>
      <p className="content-p">
        <b className="content-b">Senior local authority stakeholder</b>
      </p>
      <p className="content-p">
        You are a senior member of a local authority deciding whether your organisation should undertake this process. This toolkit will help you
        understand the benefits of making your asset data available and give you an overview of the time and resources needed to make it work.
      </p>
    </div>
  );
}

export default P1bi;
