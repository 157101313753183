import "./App.scss";
import React, { useState, useEffect, useRef } from "react";
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import ReactGA, { set } from "react-ga";
import ReactGA4 from "react-ga4";
import Home from "./Components/Home";
import Details from "./Components/Details";
import NavigationObject from "./NavigationObject.json";
import SearchResults from "./Components/SearchResults";
import StopWords from "./StopWords.json";
import ContentPage from "./Components/ContentPage";
const TRACKING_ID = "UA-254542592-1"; // OUR_TRACKING_ID
const GA4_TRACKING_ID = "G-B1DDPHT6WX";
//initialises Google analytics
ReactGA.initialize(TRACKING_ID);
ReactGA4.initialize(GA4_TRACKING_ID);

// handles the input of the search bar and finds the titles and descriptions that relate to it
function GetSearchResults(input) {
  let searchResults = [];
  input = input.toLowerCase();
  input = input.split(" ");
  input = input.filter((word) => !StopWords.all.includes(word));
  input = input.filter((word, index) => input.indexOf(word) === index);
  var inputItr = input.length;
  while (inputItr--) {
    for (let checklistItr = 0; checklistItr < NavigationObject.homePage.length; checklistItr++) {
      if (
        NavigationObject.homePage[checklistItr].title.toLowerCase().includes(input[inputItr]) ||
        NavigationObject.homePage[checklistItr].description.toLowerCase().includes(input[inputItr])
      )
        searchResults.push({ navigation: [checklistItr], occurances: 1, content: NavigationObject.homePage[checklistItr] });
      for (let sectionItr = 0; sectionItr < NavigationObject.homePage[checklistItr].sections.length; sectionItr++) {
        if (
          NavigationObject.homePage[checklistItr].sections[sectionItr].title.toLowerCase().includes(input[inputItr]) ||
          NavigationObject.homePage[checklistItr].sections[sectionItr].description.toLowerCase().includes(input[inputItr])
        )
          searchResults.push({
            navigation: [checklistItr, sectionItr],
            occurances: 1,
            content: NavigationObject.homePage[checklistItr].sections[sectionItr],
          });
        for (let linkItr = 0; linkItr < NavigationObject.homePage[checklistItr].sections[sectionItr].links.length; linkItr++) {
          if (
            NavigationObject.homePage[checklistItr].sections[sectionItr].links[linkItr].title.toLowerCase().includes(input[inputItr]) ||
            NavigationObject.homePage[checklistItr].sections[sectionItr].links[linkItr].description.toLowerCase().includes(input[inputItr])
          )
            searchResults.push({
              navigation: [checklistItr, sectionItr, linkItr],
              occurances: 1,
              content: NavigationObject.homePage[checklistItr].sections[sectionItr].links[linkItr],
            });
        }
      }
    }
  }
  //get unique
  var uniqueResults = [];
  var matchedResult = -1;
  for (var resultItr = 0; resultItr < searchResults.length; resultItr++) {
    matchedResult = uniqueResults.findIndex((result) => result.content.id === searchResults[resultItr].content.id);
    if (matchedResult === -1) {
      uniqueResults.push(searchResults[resultItr]);
    } else {
      uniqueResults[matchedResult].occurances += 1;
    }
  }

  uniqueResults = uniqueResults.sort((resultA, resultB) => {
    if (resultA.occurances > resultB.occurances) return -1;
    else if (resultA.occurances < resultB.occurances) return 1;
    return 0;
  });

  console.log(`Search Input : ${input}, results: `, uniqueResults);

  return uniqueResults;
}

function App() {
  // define state
  useEffect(() => {
    //Updates google analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  // return router
  return (
    <div className="app_root">
      <BrowserRouter basename="/">
        <Header></Header>
        <div className="contents">
          <Routes>
            <Route path="/" element={<Home GetSearchResults={GetSearchResults}></Home>}></Route>
            <Route path="Details/:id/:x/:y" element={<ContentPage></ContentPage>}></Route>
            <Route path="Details/:id/" element={<Details GetSearchResults={GetSearchResults}></Details>}></Route>
            <Route path="Search/" element={<SearchResults GetSearchResults={GetSearchResults}></SearchResults>}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
