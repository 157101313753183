import React from "react";

function P8ci() {
  return (
    <div className="content-div">
      <p className="content-p">
        A data refresh means updating an asset dataset that is already available on your digital asset platform. Data refreshes ensure that any new assets or new information about existing assets is displayed on the platform, encouraging trust in the data. 
      </p>

      <h3 className="content-h3">Importance of asset IDs</h3>
      <p className="content-p">
        Platform providers rely on consistent unique asset IDs to refresh asset data. When you send an updated dataset to your platform provider, they use the unique ID as the reference point and will update any information related to each ID. The unique ID is also the reference point for any communication that happens on the platform regarding that asset.  If a new asset has been added to the dataset, that unique ID will be used to display the new asset on the platform. 
      </p>
      <p className="content-p">Do not change the unique ID associated with an asset. Your platform provider will be unable to link new information with the correct asset. Existing communications regarding that asset will also be lost.</p>

      <h3 className="content-h3">Data refresh frequency</h3>
      <p className="content-p">
        Discuss the appropriate data refresh frequency with your platform provider and with asset owners. This depends on how frequently a dataset is updated and the level of change associated with that update. Some datasets, such as land, are unlikely to change frequently and may only need an annual refresh. However, a dataset such as street lighting may be updated frequently, requiring a refresh every three months.
      </p>
      <p className="content-p">
        Contact your digital asset platform provider if you need to refresh asset data sooner than planned, such as when a dataset has been updated with many changes. 
      </p>
    </div>
  );
}

export default P8ci;
