import React from "react";

function P8_glossary() {
  return (
    <div className="content-div">
      <ul className="content-ul">
        <li className="content-li">
          <b className="content-b">DCIA</b>: Digital Connectivity Infrastructure Accelerator
        </li>
        <li className="content-li">
          <b className="content-b">Digital asset platform</b>: GIS platform where local authorities can upload their assets and create an opportunity for industry users to use those assets. 
        </li>
        <li className="content-li">
          <b className="content-b">DSIT</b>: Department for Science, Innovation & Technology
        </li>
        <li className="content-li">
          <b className="content-b">GIS</b>: A Geographic Information System (GIS) is a system to store, capture, analyse, manage, edit and visualise geographic data.
        </li>
      </ul>
    </div>
  );
}

export default P8_glossary;
