import React from "react";
import Download from "../Components/Download";
import VersionDownload from "../Components/VersionDownload";

function P8ei() {
  return (
    <div className="cotent-div">
      <p className="content-p">
        Use the attached template as a guide to define your data governance
        roles and responsibilities. Every stakeholder involved in the process
        should be part of the discussions.
      </p>

      <VersionDownload
        download_titles={[
          "Microsoft Office",
          "LibreOffice",
          "Apache OpenOffice",
        ]}
        download_options={[
          <Download
            content={{
              title: "Data Governance Framework Template",
              details: "Word Document, 25 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 8 (Long term data operations)/MS Office/Data Governance Framework Template.docx"
            }
          />,
          <Download
            content={{
              title: "Data Governance Framework Template",
              details: "ODT Document, 27 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 8 (Long term data operations)/Libre Office/Data Governance Framework Template.odt"
            }
          />,
          <Download
            content={{
              title: "Data Governance Framework Template",
              details: "ODT Document, 18 KB",
              description: "",
            }}
            url={
              "/Download-files/Heading 8 (Long term data operations)/Apache Open Office/Data Governance Framework Template.odt"
            }
          />,
        ]}
      />
    </div>
  );
}

export default P8ei;
