import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import Navbar from "./Navbar.js";

function Header(props) {
  return (
    <div className="Header">
      <header className="grid-row boxed-header">
        <div className="header-div">
          <Link to="." className="header-link">
            <p className="header-text">DCIA Data Integration Toolkit</p>
          </Link>
          <Navbar />
        </div>
      </header>
    </div>
  );
}

export default Header;
