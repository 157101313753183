import React from "react";

function P8di() {
  return (
    <div className="content-div">
      <p className="content-p">
        Maintain regular communications with your platform provider to help improve data quality. They will provide feedback on the quality of your asset data, which you can pass on to asset owners and data processors.
      </p>
      <p className="content-p">
      Different platform providers will have different methods of doing this, but most likely it will be a Microsoft Excel document or equivalent. Be sure to agree which method works best, what information needs to be provided and the type of response they expect from you.
      </p>
    </div>
  );
}

export default P8di;
