import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Breadcrumbs";
import ManualTitle from "../Components/ManualTitle";
import NavigationObject from "../NavigationObject.json";
import "./Home.scss";
import ContentsButton from "../Components/ContentsButton";
import ContentDict from "../Content-Pages/ContentDict";

function Home(props) {
  return (
    <>
      <Breadcrumbs page="Home"></Breadcrumbs>
      <ManualTitle GetSearchResults={props.GetSearchResults}></ManualTitle>
      <div className="manual">
        <ContentsButton />
        <div className="manual-body">
          <div className="govspeak">{ContentDict["1_intro"]}</div>
          <ul className="document-list">
            {NavigationObject.homePage.map((element, index) => {
              return (
                <li className="document-list__item" key={element.title}>
                  <Link className="document-list__item-title govuk-link" to={"Details/" + element.id + "/"}>
                    {index + 1}. {element.title}
                  </Link>
                  <p className="document-list__item-description">{element.description}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;
