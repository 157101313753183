import React from "react";

function P2bi() {
  return (
    <div className="content-div">
      <p className="content-p">
        Different local authorities will choose to engage in this process in
        different ways. The activities and roles listed are based on the most
        likely processes a local authority will choose to implement.
      </p>
      <h3 className="content-h3">Mapping your data ecosystem</h3>
      <p className="content-p">
        Key tasks in this stage include data discovery, engaging with
        stakeholders, understanding the data landscape and documenting your
        findings.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to engage with stakeholders and
          collect information{" "}
        </li>
        <li className="content-li">
          Data processors to advise on the datasets they manage
        </li>
        <li className="content-li">
          Asset owners to provide information on datasets
        </li>
        <li className="content-li">
          Senior local authority stakeholders to provide guidance on what
          stakeholders to contact
        </li>
      </ul>
      <h3 className="content-h3">Acquiring the data</h3>
      <p className="content-p">
        Key tasks in this stage include deciding what data attributes to
        request, requesting data from asset owners, and keeping the data secure.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to engage with and collect
          datasets from relevant stakeholders
        </li>
        <li className="content-li">
          Data processors to provide datasets to the relevant stakeholder
        </li>
        <li className="content-li">
          Asset owners to provide datasets to the relevant stakeholder
        </li>
      </ul>
      <h3 className="content-h3">Cleaning your data</h3>
      <p className="content-p">
        Key tasks in this stage include assessing the acquired datasets and
        transforming the datasets to your digital asset platform provider’s
        requirements.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to coordinate the data
          transformation process and clean the datasets
        </li>
        <li className="content-li">
          Data processors to clean datasets to the digital asset platform
          provider’s requirements
        </li>
        <li className="content-li">
          Asset owners to provide more information about datasets if requested
        </li>
      </ul>
      <h3 className="content-h3">Checking the accuracy of your data</h3>
      <p className="content-p">
        Key tasks in this stage include checking the location accuracy of the
        asset data.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to check the accuracy of the
          asset data
        </li>
        <li className="content-li">
          Data processors to check the accuracy of the asset data
        </li>
      </ul>
      <h3 className="content-h3">
        Transferring data to your platform provider
      </h3>
      <p className="content-p">
        Key tasks in this stage include agreeing a transfer method with your
        digital asset platform provider, keeping the data secure and
        transferring the data to the digital asset platform provider in the
        agreed format.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to agree a transfer method with
          the digital asset platform provider
        </li>
        <li className="content-li">
          Data processors to transfer the data to the platform provider
        </li>
      </ul>
      <h3 className="content-h3">Long-term data operations</h3>
      <p className="content-p">
        Key tasks in this stage include creating a target operating model and
        accompanying change management programme, agreeing a data governance
        structure, and engaging with requests from your digital asset platform
        provider.
      </p>
      <p className="content-p">Roles required:</p>
      <ul className="content-ul">
        <li className="content-li">
          Digital coordinators/ Data champions to coordinate the long-term
          operation of the local authority undertaking this process
        </li>
        <li className="content-li">
          Data processors to help with data refreshes and continue to clean any
          new datasets that become available
        </li>
        <li className="content-li">
          Asset owners to provide information on current datasets and advise on
          any new asset datasets that may be applicable
        </li>
        <li className="content-li">
          Senior local authority stakeholders to provide the resources to make
          the process sustainable in the long run for the local authority
        </li>
      </ul>
    </div>
  );
}

export default P2bi;
